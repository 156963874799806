@use "src/scss/colors" as *;

.Notification {
  background-color: $purple500;
  padding: 16px 24px;
  border-radius: 8px;
  margin: 32px 0;

  display: flex;
  justify-content: space-between;
  align-items: center;

  &__content {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .Icon {
      margin-right: 16px;

      path {
        stroke: $purple100;
      }
    }
  }

  &__text {
    font-size: 16px;
    line-height: 24px;
    color: $purple50;
    padding-top: 3px;
  }

  &__action {
    display: flex;
    height: 40px;
    align-items: center;

    border: none;
    border-radius: 40px;
    padding: 8px 16px;
    font-size: 18px;
    color: $purple200;
    background: $purple600;
    cursor: pointer;

    .Icon {
      path {
        stroke: $purple200;
      }
    }

    &:hover {
      color: $purple100;
      background-color: $purple700;

      .Icon path {
        stroke: $purple100;
      }
    }

    &:active {
      color: $purple200;
      background-color: $purple700;

      .Icon path {
        stroke: $purple200;
      }
    }

    .Button__label {
      padding-top: 3px;
    }
  }
}
