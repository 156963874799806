@use "src/scss/colors" as *;

.MenuItem {
  display: flex;
  align-items: center;
  padding: 0 12px;
  height: 46px;
  text-decoration: none;
  color: $white;
  font-size: 16px;
  font-weight: 600;
  border-radius: 8px;

  &:hover {
    background-color: $black800;
  }

  &:active {
    background-color: $black775;
    color: $purple100;

    & .MenuItem__Icon {
      path {
        stroke: $purple100;
      }
    }
  }

  &--active {
    background-color: $black850;
    color: $purple200;

    &:hover {
    }
  }

  &__Icon {
    margin-right: 12px;

    path {
      stroke: $white;
    }
  }

  &--active &__Icon path {
    stroke: $purple200;
  }

  &__label {
    padding-top: 5px;
  }
}
