@use "src/scss/colors" as *;

.Priority {
  margin: 0;

  &__fieldset {
    border: none;
    padding: 0;
    margin: 0 0 16px -1px;

    display: flex;
  }
  &__legend {
    display: none;
  }
  &__radio {
    display: none;
  }

  &__label {
    background-color: $black900;
    color: $black300;
    font-weight: bold;
    font-size: 11px;
    text-transform: uppercase;
    padding: 7px 8px 5px;
    margin: 0 1px;
    user-select: none;

    &:hover {
      background-color: $black800;
      cursor: pointer;
    }

    &:active {
      background-color: $black750;
    }
  }

  &__level:first-of-type &__label {
    border-radius: 30px 0 0 30px;
    padding-left: 10px;
  }

  &__level:last-of-type &__label {
    border-radius: 0 30px 30px 0;
    padding-right: 10px;
  }

  &__radio:checked + &__label {
    background-color: $black775;
  }
  &__radio:checked + &__label--low {
    color: $green300;
  }
  &__radio:checked + &__label--medium {
    color: $orange300;
  }
  &__radio:checked + &__label--high {
    color: $red300;
  }
}
