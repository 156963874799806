@use "src/scss/colors" as *;

.ArrowLink {
  display: inline-flex;

  text-decoration: none;
  font-size: 13px;
  font-weight: 600;
  color: $purple300;

  white-space: nowrap;

  .Icon {
    margin-left: 3px;

    path {
      stroke: $purple300;
    }
  }

  &:hover {
    color: $purple200;

    .Icon path {
      stroke: $purple200;
    }
  }

  &:active {
    color: $purple100;

    .Icon path {
      stroke: $purple100;
    }
  }

  &__label {
    padding-top: 2px;
  }
}
