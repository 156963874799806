@use "src/scss/colors" as *;

.TaskStatus {
  margin: 0 0 24px;

  &:last-child {
    margin-bottom: 0;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 24px;
  }

  &__title {
    font-size: 20px;
    margin: 0;
    font-weight: 500;
  }

  &__link {
    text-decoration: none;
    color: $white;
    background-color: rgba($black, 0.8);
    padding: 8px 16px;
    font-weight: 600;
    border-radius: 50px;

    &:hover {
      background-color: rgba($black, 0.9);
    }
  }
}
