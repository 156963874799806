@use "src/scss/colors" as *;

.Tags {
  margin: 16px 0 0;

  &__list {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
  }

  &__tag {
    border-radius: 4px;
    padding: 8px 8px 5px;
    font-size: 13px;
    margin-right: 8px;
    background-color: $black900;
    color: $black300;
    font-weight: 600;
  }
}
