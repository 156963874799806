@use "src/scss/colors" as *;
@use "src/scss/mixins" as *;

.TrustedBy {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;

  @include whenmax(499px) {
    align-items: stretch;
  }

  &__title {
    font-size: 48px;
    font-weight: 600;
    line-height: 1.3333em;
    color: $white;
    margin: 0 0 32px;

    @include when($small-down) {
      font-size: 38px;
      margin-bottom: 16px;
    }

    @include whenmax(399px) {
      font-size: 32px;
    }
  }

  &__cards {
    display: flex;
  }

  &__card {
    box-sizing: border-box;
    margin: 0 8px;
    width: 50%;
    padding: 6%;
    background-color: $black900;
    border-radius: 16px;
    display: flex;
    align-items: center;

    @include when($small-down) {
      margin: 0 4px;
      &:nth-child(odd) {
        margin-left: 0;
      }
      &:nth-child(even) {
        margin-right: 0;
      }
    }
  }

  &__logo {
    width: 100%;
    height: auto;
  }
}
