@use "src/scss/colors" as *;

.CurrentCoins {
  &__loading {
    vertical-align: bottom;
    margin: 0 8px;
  }

  &__blurbs {
    display: flex;
    justify-content: space-between;
    margin: 0 -16px;
  }
}

.Blurb {
  margin: 0 16px;
  &__title {
    font-size: 13px;
    line-height: 1.5;
    font-weight: bold;
    margin: 0 0 18px;
    color: $black50;
    white-space: nowrap;
  }

  &__value {
    font-size: 34px;
    line-height: 34px;
    font-weight: bold;
    color: $black50;
  }

  &__unit {
    font-size: 13px;
    line-height: 1.5;
    font-weight: bold;
    margin: 0 0 18px;
    color: $black50;
  }

  &__link {
    margin-top: 16px;
  }
}

.CoinHistory {
  border-top: 1px solid $black800;
  margin-top: 32px;
  padding-top: 32px;

  &__title {
    margin: 0;
    font-size: 24px;
    line-height: 1.3333;
  }

  &__list {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  &__item {
    display: flex;
    align-items: flex-start;
    padding: 12px 0;
    border-bottom: 1px solid $black800;

    &--emptyMessage {
      height: 52px;
      align-items: center;
    }
  }

  &__date {
    height: 52px;
    display: flex;
    align-items: center;

    font-weight: normal;
    font-size: 16px;
    line-height: 1.3;
    color: $black400;
    width: 196px;
    margin: 0;
    padding: 0;

    span {
      padding-top: 3px;
    }
  }

  &__events {
    list-style: none;
    padding: 0;
    margin: 0;
    flex-grow: 1;
  }

  &__event {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 52px;
  }

  &__eventMeta {
    display: flex;
    align-items: center;
    width: 176px;

    .Icon {
      margin-right: 8px;
    }
  }

  &__eventLabel {
    display: flex;
    font-size: 16px;
    font-weight: bold;
    line-height: 1.3;
    color: $black50;
    margin: 0;
    padding: 0;

    span {
      padding-top: 3px;
    }
  }

  &__eventContent {
    display: flex;
    align-items: center;
    color: $black50;

    font-weight: bold;
  }
}
