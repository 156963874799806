@use "src/scss/colors" as *;
@use "src/scss/mixins" as *;

.UseCases {
  //
}

.UseCasesBox {
  &__outer {
    background-color: $black875;
    padding: 32px;

    @include when($small-down) {
      padding: 16px;
    }

    @include whenmax(399px) {
      padding: 8px;
    }
  }
}

.UseCasesNav {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  gap: 4px;
  margin-bottom: 16px;

  @include when($small-down) {
    flex-wrap: wrap;
    padding: 8px;
    background-color: rgba($purple100, 0.05);
    border-radius: 16px;
  }

  @include whenmax(399px) {
    padding: 8px;
    border-radius: 24px;
  }

  &__link {
    display: flex;
    align-items: center;
    padding: 16px 8px;
    text-decoration: none;
    font-size: 15px;
    line-height: 22px;
    font-weight: 500;
    text-align: center;
    border-radius: 16px;
    box-sizing: border-box;

    color: $black300;

    @include when($small-down) {
      width: calc(50% - 2px);
      height: 32px;
      font-size: 14px;
      line-height: 1.333;
      padding: 1px 16px 0;
      border-radius: 8px;
      font-weight: 600;
      text-align: left;
      white-space: nowrap;
    }

    @include whenmax(399px) {
      padding: 1px 12px 0;
      font-size: 12px;
      border-radius: 16px;
    }

    &:hover {
      background: rgba($purple100, 0.03);
    }

    &:active {
      background: rgba($purple100, 0.08);
    }
  }

  &__current {
    color: $purple200;

    background: rgba($purple100, 0.05);

    &:hover,
    &:active {
      background: rgba($purple100, 0.05);
    }
  }
}

.UseCase {
  .UseCaseHeader {
    &__outer {
      margin-bottom: 16px;

      padding: 48px;

      @include when($small-down) {
        padding: 24px;
      }

      @include whenmax(399px) {
        padding: 20px 10px;
      }
    }

    &__title {
      margin: 0;
      font-size: 48px;
      font-weight: 600;
      line-height: 1.25;
      color: $white;

      @include when($small-down) {
        font-size: 28px;
      }
    }

    &__subtitle {
      margin: 4px 0 0;
      font-size: 20px;
      line-height: 1.5;
      font-weight: 600;
      color: $white;

      @include when($small-down) {
        font-size: 18px;
        margin: 6px 0 0;
      }
    }

    &__desc {
      margin: 16px 0 0;
      font-size: 16px;
      line-height: 1.5;
      color: $black300;
    }
  }

  &__sections {
    display: flex;
    margin-left: -16px; /* gutter size offset */
    width: auto;
  }

  &__column {
    padding-left: 16px; /* gutter size */
    background-clip: padding-box;

    > div {
      margin-bottom: 16px;

      @include when($small-down) {
        //margin-bottom: 8px;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .MediaBox {
    padding: 48px 0;

    &--mediafirst {
      @include when($medium-down) {
        padding: 0 0 48px;
      }

      @include when($small-down) {
        padding: 0 0 32px;
      }

      @include whenmax(399px) {
        padding: 0 0 24px;
      }
    }

    &--nomedia {
      @include when($medium-down) {
        padding: 48px 0;
      }

      @include when($small-down) {
        padding: 32px 0;
      }

      @include whenmax(399px) {
        padding: 24px 0;
      }
    }

    &__outer {
      border-radius: 24px;
      padding: 0 48px;

      @include when($small-down) {
        padding: 0 24px;
      }

      @include whenmax(399px) {
        padding: 0 16px;
      }
    }

    &__video {
      height: 0;
      overflow: hidden;
      padding-top: 58%;
      position: relative;
      border-radius: 16px;

      @include when($medium-down) {
        padding-top: calc((100% + 96px) * 0.58);
        margin-left: -48px;
        width: calc(100% + 96px);
        border-radius: 24px;
      }

      @include when($small-down) {
        margin-left: -24px;
        padding-top: calc((100% + 48px) * 0.58);
        width: calc(100% + 48px);
        border-radius: 24px;
      }

      @include whenmax(399px) {
        margin-left: -16px;
        padding-top: calc((100% + 32px) * 0.58);
        width: calc(100% + 32px);
        border-radius: 24px;
      }

      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }

    &__title {
      font-size: 26px;
      line-height: 1.3;
      color: $white;

      @include when($small-down) {
        font-size: 24px;
      }

      @include whenmax(399px) {
        font-size: 22px;
      }
    }

    &__text {
      font-size: 18px;
      line-height: 1.555;
      color: $black300;

      @include when($small-down) {
        font-size: 16px;
      }
    }

    &__moretext {
      ul {
        list-style: none;
        padding: 0 0 0 16px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      li {
        position: relative;
        font-weight: 500;
        font-size: 16px;
        line-height: 1.375;
        color: $black300;

        &::before {
          content: "";
          width: 3px;
          height: 3px;
          position: absolute;
          top: 8px;
          left: -12px;
          background-color: $purple200;
        }
      }
    }
  }
}
