@use "src/scss/colors" as *;

.Footer {
  margin-top: 30px;
  font-size: 12px;
  color: $black500;

  &__list {
    flex-grow: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;

    font-size: 12px;
    text-transform: uppercase;
  }

  &__item {
    color: $black500;
    margin-right: 8px;
    text-decoration: underline;

    &.MenuItem--active {
      border-color: transparent;
    }
  }
}