@use "src/scss/colors" as *;

.SignOutLink {
  display: flex;
  align-items: center;
  padding: 0 12px;
  height: 46px;
  text-decoration: none;
  color: $white;
  font-size: 16px;
  font-weight: 600;
  border-radius: 8px;

  &:hover {
    background-color: $black800;
  }
}
