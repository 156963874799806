/* Colors from Figma */
$white: #FFFFFF;

$black25: #F9F9F9;
$black50: #F2F2F2;
$black100: #E5E5E5;
$black200: #CCCCCC;
$black300: #B2B2B2;
$black400: #999999;
$black500: #808080;
$black600: #666666;
$black650: #595959;
$black700: #4C4C4C;
$black725: #464646;
$black750: #404040;
$black775: #393939;
$black800: #333333;
$black825: #2D2D2D;
$black850: #262626;
$black875: #202020;
$black900: #191919;
$black1000: #000000;

$purple25: #F8F3FD;
$purple50: #F0E7FC;
$purple100: #E3D6FF;
$purple200: #BEA0FF;
$purple300: #9A6FF8;
$purple400: #803BFA;
$purple500: #6A0FDE;
$purple600: #550CB2;
$purple700: #400985;
$purple800: #2A0659;

$yellow300: #FFE666;

$orange300: #FFCB66;

$green300: #66D794;

$blue25: #F4F9FD;
$blue50: #E9F3FC;
$blue200: #A7CDF1;
$blue300: #7AB5EA;
$blue400: #4E9CE3;
$blue500: #2283DC;
$blue600: #1B69B0;
$blue700: #144F84;
$blue800: #0E3458;
$blue900: #071A2C;

$turquoise300: #66D7D7;

$pink300: #E079CA;

$red300: #E68975;
$red400: #DE6247;
$red500: #D63B19;
$red600: #AB2F14;


$outsidepurple1: #231936;
$outsidepurple2: #2C1F44;


/* Wireframe colors */
$black: #000000;
$yellow: #FFE898;
$green: #00B780;
$red: #FC5E63;
$pink: #FFEBEB;
$darkblue: #013763;
$bgcolor: $black900;