@use "src/scss/colors" as *;
@use "src/scss/mixins" as *;

.TransformationsList {
  &__loading {
    margin: 48px 0 0;
  }

  &__section {
    margin: 48px 0 0;
  }

  &__sectionHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 26px;
  }

  &__title {
    font-weight: font-weight(regular);
    font-size: 14px;
    line-height: 20px;
    margin: 0;
    line-height: 8px;
  }

  &__count {
    font-weight: font-weight(bold);
    font-size: 12px;
  }

  &__items {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 8px;
  }

  &__item {
    padding: 12px;
    border-radius: 8px;
    text-decoration: none;
    color: $purple50;
    background-color: $black875;

    &:hover {
      background-color: $black850;
    }

    &:active {
      background-color: $black800;
    }

    &--current {
      background-color: $black825;
      color: $white;
    }
  }

  &__label {
    font-size: 14px;
    line-height: 16px;
    font-weight: font-weight(medium);
    margin-bottom: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__meta {
    font-size: 10px;
    line-height: 12px;
    color: $purple200;
    font-weight: font-weight(bold);
  }
}