@use "src/scss/colors" as *;

.Logo {
  line-height: 50px;
  display: inline-flex;
  align-items: center;

  &__image {
    margin-right: 8px;
    margin-top: -7px;
  }

  &__name {
    font-weight: 500;
    text-transform: uppercase;
    color: $black;
    font-size: 18px;
  }
}
