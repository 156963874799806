@use "src/scss/colors" as *;

.BackLink {
  color: $black500;
  text-decoration: none;
  font-weight: bold;
  font-size: 16px;
  height: 44px;
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  user-select: none;

  .Icon {
    margin-right: 16px;
    path {
      stroke: $black500;
    }
  }

  &:hover {
    color: $black300;

    .Icon path {
      stroke: $black300;
    }
  }

  &:active {
    color: $black100;

    .Icon path {
      stroke: $black100;
    }
  }

  &__label {
    padding-top: 3px;
  }
}
