@use "src/scss/colors" as *;
@use "src/scss/mixins" as *;

.OutsideContent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  position: relative;
  width: 100%;
  padding-top: 80px;

  @include when($small-down) {
    padding-top: 48px;
  }

  @include when($huge-up) {
    background-size: 100% auto;
  }

  @include when($small-down) {
    background-size: 1200px auto;
  }

  @include whenmax(549px) {
    background-size: 1100px auto;
  }

  @include whenmax(429px) {
    background-size: 1000px auto;
  }

  @include whenmax(400px) {
    background-size: 880px auto;
  }

  &--frontpage {
    padding-top: 0;
  }

  &__background {
    position: absolute;
    top: -10px;
    left: 0px;
    right: 0px;
    width: 100%;
    height: 100%;
    background-position: top center;
    background-size: 1440px auto;
    background-repeat: no-repeat;
    z-index: -1;

    @include when($huge-up) {
      background-size: 100% auto;
      top: calc((1422px - 100vw) * 0.572);
    }

    @include whenmax(650px) {
      top: calc((638px - 90vw) * -1);
    }

    @include whenmax(450px) {
      top: calc((578px - 90vw) * -1);
    }
  }

  &__lights {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: auto;
    max-height: 1440px;
    mix-blend-mode: soft-light;
    opacity: 0;
    animation: fadeIn 1000ms ease-in forwards;
    will-change: transform;

    #light1 {
      opacity: 0.6;
      animation: light1 10000ms ease-in forwards alternate;
    }

    #light2 {
      opacity: 0.6;
      animation: light2 10000ms ease-in forwards alternate;
    }

    #light3 {
      opacity: 0.6;
      animation: light3 10000ms ease-in forwards alternate;
    }

    @include when($huge-up) {
      top: calc((1422px - 100vw) * 0.572);
    }

    @include whenmax(650px) {
      top: calc((638px - 90vw));
    }

    @include whenmax(450px) {
      top: calc((578px - 90vw));
    }
  }

  @keyframes fadeIn {
    0% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes light1 {
    0% {
      transform: translate(-20%, -30%);
    }
    100% {
      transform: translate(0%, 0%);
    }
  }
  @keyframes light2 {
    0% {
      transform: translate(20%, 30%);
    }
    100% {
      transform: translate(0%, 0%);
    }
  }
  @keyframes light3 {
    0% {
      transform: translate(30%, -20%);
    }
    100% {
      transform: translate(0%, 0%);
    }
  }
}
