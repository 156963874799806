@use "src/scss/mixins" as *;

.Main {
  box-sizing: border-box;
  padding: var(--vPadding) var(--hPadding) calc(var(--vPadding) * 2) calc(var(--hPadding) + var(--sidebarWidth));

  width: 100%;
  overflow-x: hidden;
  //max-width: 1440px;
  min-height: 100%;
  margin: 104px auto 0;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &--withPageSidebar {
    padding: var(--vPadding) var(--hPadding) calc(var(--vPadding) * 2) calc(var(--hPadding) + var(--sidebarWidth) + var(--pageSidebarWidth));
  }

  &--withoutSidebar {
    padding: var(--vPadding) var(--hPadding);
    max-width: 1024px;
  }
}