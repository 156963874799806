@use 'src/scss/colors' as *;


.Code {
  background-color: $black850;
  border-left: 2px solid $purple500;
  padding: 8px 16px;
  margin: 16px 0;

  &__toggle {}

  &__content {
    display: flex;

    pre {
      white-space: pre-wrap;
      width: 100%;
      overflow: hidden;
    }
  }

  &--toggle &__content--hide {
    display: none;
  }
}