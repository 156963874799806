@use "src/scss/colors" as *;

.datatable-container {
  font-family: sans-serif;
  background-color: var(--dt-background-color-container);
  border-radius: var(--dt-border-radius);
  color: var(--dt-text-color);
  max-width: 1140px;
  min-width: 950px;
  margin: 0 auto;
  font-size: 12px;
}

.datatable-container .datatable,
.datatable-container .datatable th,
.datatable-container .datatable td {
  padding: var(--dt-padding) var(--dt-padding);
}

.datatable-container .datatable th {
  font-weight: bolder;
  text-align: left;
  border-bottom: solid 1px var(--dt-border-color);
}

.datatable-container .datatable td {
  border-bottom: solid 1px var(--dt-border-color);
}

.datatable-container .datatable tbody tr:nth-child(even) {
  background-color: var(--dt-even-row-color);
}

.datatable-container .datatable tbody tr:hover {
  background-color: var(--dt-hover-cell-color);
}

.datatable-container .datatable tbody tr .available::after,
.datatable-container .datatable tbody tr .away::after,
.datatable-container .datatable tbody tr .offline::after {
  display: inline-block;
  vertical-align: middle;
}

.TaskEditor {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 32px;
  }

  &__title {
    margin-top: 0;

    .Input {
      font-size: 20px;
      padding-top: 4px;
      line-height: 50px;
    }
  }

  &__link {
    color: $black;
  }

  &__submit {
    margin-top: 16px;
  }
  &__submit_xform {
    margin-top: 16px;
  }
  &__submit_metric {
    margin-top: 16px;
  }

  &__validation {
    display: none;

    &--invalid {
      display: block;
      color: $red;
      font-weight: bold;
      font-size: 14px;
      margin-top: 8px;
    }
  }
  &__status {
    margin-top: 32px;
    display: block;
    color: $red;
    font-weight: bold;
    font-size: 18px;

    &--done {
      color: $green;
    }
  }
}
