@use "src/scss/colors" as *;

.Tab {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  list-style: none;
  margin-right: 32px;

  &__link {
    display: flex;
    align-items: center;
    padding-top: 6px;
    height: 40px;

    text-decoration: none;
    font-size: 14px;
    font-weight: 600;

    color: $black400;
    border-bottom: 2px solid transparent;

    &:hover {
      color: $black300;
      border-color: $black700;

      & .Tab__Icon {
        path {
          stroke: $black300;
        }
      }

      & .Tab__Meta {
        background-color: $black300;
      }
    }

    &:active {
      color: $purple200;
      border-color: $purple200;

      & .Tab__Icon {
        path {
          stroke: $purple200;
        }
      }

      & .Tab__Meta {
        background-color: $purple200;
      }
    }

    .Tab--active & {
      color: $purple300;
      border-bottom: 2px solid $purple300;

      & .Tab__Icon {
        path {
          stroke: $purple300;
        }
      }

      & .Tab__Meta {
        background-color: $purple300;
      }
    }
  }

  &__Icon {
    margin-right: 4px;

    path {
      stroke: $black400;
    }
  }

  &__label {
    padding-top: 3px;
  }

  &__Meta {
    margin-left: 4px;
    box-sizing: border-box;
    padding-top: 3px;
    min-width: 20px;
    height: 20px;
    display: inline-flex;
    border-radius: 20px;
    justify-content: center;
    align-items: center;
    color: $black800;
    background-color: $black400;
    font-weight: bold;
    font-size: 11px;
  }
}
