@use "src/scss/colors" as *;
@use "src/scss/mixins" as *;

.Hero {
  &__top {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 714px;
    z-index: 2;
    position: relative;

    padding: 120px 20px 0;

    @include when($small-down) {
      padding-top: 120px;
      height: 722px;
    }

    @include whenmax(650px) {
      height: calc(90vw + 106px);
    }

    @include whenmax(549px) {
      padding-top: 100px;
    }

    @include whenmax(450px) {
      height: calc(90vw + 162px);
    }
  }

  &__outer.OutsideBox {
    max-width: none;
    padding: 0px;
    background: none;
    overflow: visible;
  }

  &__inner {
    position: relative;
    width: 100%;
    max-width: 840px;
  }

  &__title {
    font-size: 60px;
    font-weight: 600;
    line-height: 1.06667em;
    margin: 0 auto 8px;
    padding: 0;
    max-width: 500px;
    text-align: center;
    position: relative;
    z-index: 2;

    @include when($small-down) {
      font-size: 48px;
      max-width: 360px;
    }

    @include whenmax(549px) {
      font-size: 40px;
      max-width: 300px;
    }
  }

  &__tagline {
    font-size: 20px;
    font-weight: 600;
    line-height: 1.2em;
    max-width: 400px;
    text-align: center;
    margin: 0 auto -15px;
    position: relative;
    z-index: 2;

    @include when($small-down) {
      margin-bottom: 16px;
    }

    @include whenmax(549px) {
      font-size: 16px;
      max-width: 300px;
      margin-bottom: 32px;
    }
  }

  &__model {
    position: relative;
    margin: 0 13px;

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      mask-image: url(cognyModelMask.svg);
      mask-size: contain;
      mask-repeat: no-repeat;
      backdrop-filter: blur(4px);

      z-index: 2;
    }

    &--small {
      &::after {
        mask-image: url(cognyModelMaskSmall.svg);
      }
    }

    @include whenmax(450px) {
      margin: 0 -15px;
    }
  }

  &__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    margin: 0;
    z-index: 1;

    &--front {
      position: relative;
      top: auto;
      left: auto;
      z-index: 3;
    }

    &--back {
      .signal1,
      .signal4,
      .signal8 {
        animation: signals 1000ms linear forwards infinite;
      }

      .signal2,
      .signal5,
      .signal7 {
        animation: signals 1100ms linear forwards infinite;
      }

      .signal3,
      .signal6 {
        animation: signals 900ms linear forwards infinite;
      }
    }
  }

  @keyframes signals {
    0% {
      stroke-dashoffset: 0px;
    }
    100% {
      stroke-dashoffset: 21px;
    }
  }

  &__bottom {
    box-sizing: border-box;
    position: relative;
    margin: 0 auto;
    z-index: 1;

    width: 100%;
    max-width: 840px;
    border-radius: 0 0 32px 32px;
    background-color: $outsidepurple1;
    padding: 180px 80px 40px;

    mask: linear-gradient(-12.5deg, white 60%, transparent 60%);
    mask-repeat: no-repeat;

    @include whenmax(650px) {
      padding-top: 140px;
    }
  }

  &__button {
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 276px;
    background-image: linear-gradient(
      94.68deg,
      #393c7b 0%,
      #4a5494 21.35%,
      #6162a9 40.62%,
      #8563b7 61.46%,
      #a66d73 80.21%,
      #be7c8b 100%
    );
    border-radius: 16px;
    margin: 0 auto;
    padding: 24px 0;

    color: $purple50;
    font-size: 24px;
    font-weight: 600;
    text-decoration: none;

    @include when($small-down) {
      font-size: 15px;
      width: 176px;
      padding: 16px 0;
    }

    &:hover {
      opacity: 0.9;
    }

    &:active {
      opacity: 1;
      color: $white;
    }
  }

  &__buttonLabel {
    padding-top: 3px;
    margin: 0 12px 0 0;

    @include when($small-down) {
      margin-right: 8px;
    }
  }

  &__buttonArrow {
    @include when($small-down) {
      width: 16px;
    }
  }
}
