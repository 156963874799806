@use "src/scss/colors" as *;

.ModalDialog {
  width: 90vw;
  max-width: 900px;
  max-height: calc(100vh - 20px);
  padding: 0;
  background-color: $black850;
  border: 0;
  border-radius: 8px;
  box-shadow: 0 16px 32px rgba(black, .5);
  overflow: hidden;

  &__toolbar {
    padding: 16px;
    display: flex;
    gap: 16px;
  }

  .Code {
    border-left: none;
    padding: 0;
    margin: 0;

    pre {
      max-height: 400px;
      overflow: auto;
      padding: 16px !important;
      margin: 0;
    }
  }

  &::backdrop {
    background-color: rgba(black, .5);
  }
}