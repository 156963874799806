@use "src/scss/colors" as *;

.DatasourceDetails {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin: 32px 0 24px;
  }

  &__title {
    flex-grow: 1;
    margin-right: 16px;
    margin: 0;
  }

  &__tags.Tags {
    margin-top: 32px;
    margin-bottom: 16px;

    .Tags__tag {
      color: $black300;
      background-color: $black825;
    }
  }

  &__ga4info {
    font-weight: 500;
    border-bottom: 1px solid $black800;
    padding: 0 0 32px 0;
    margin: 32px 0;
  }

  &__toolbar {
    display: inline-flex;
    gap: 8px;
    background-color: $black875;
    padding: 8px;
    border-radius: 8px;
    margin-bottom: 32px;
  }

  &__delete {
    margin-left: auto;
  }
}

.DatasourceTables {
  border-top: 1px solid $black800;
  padding: 32px 0 16px;
  margin-top: 32px;
  margin-bottom: 32px;
  border-bottom: 1px solid $black800;

  &--empty {
    padding-bottom: 32px;
    margin-bottom: 32px;
    border-bottom: 1px solid $black800;
  }

  &__header {
    display: flex;
    justify-content: space-between;
  }

  &__title {
    font-size: 24px;
    line-height: 1.333;
    font-weight: 600;
    margin: 0 0 32px;
    padding: 0;
  }

  &--empty &__title {
    margin-bottom: 0;
  }

  &__sort {}

  &__columns {
    display: flex;
    align-items: center;
    font-size: 13px;
    font-weight: 600;
    line-height: 20px;
    border-bottom: 1px solid $black800;
    padding-bottom: 8px;
  }

  &__name {
    margin-right: 16px;
    flex-grow: 1;
  }

  &__line {
    margin-right: 32px;
    width: 135px;
  }

  &__usage {
    width: 100px;
    text-align: right;
  }
}

.DatasourceTable {
  background-color: $black875;
  border-radius: 8px;
  padding: 16px;
  margin-top: 16px;

  &__inner {
    display: flex;
    align-items: center;
  }

  &__name {
    margin-right: 16px;
    flex-grow: 1;
  }

  &__line {
    margin-right: 16px;
    width: 160px;
  }

  &__lineMissing {
    color: $purple200;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 1px;
  }

  &__usage {
    width: 72px;
    font-weight: bold;
    font-size: 14px;
    text-align: right;
  }
}