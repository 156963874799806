@use "src/scss/colors" as *;
@use "src/scss/mixins" as *;

.OutsideBox {
  width: 100%;
  max-width: 840px;
  border-radius: 32px;
  background-color: $black850;
  margin: 0 auto 80px;
  padding: 80px;
  box-sizing: border-box;
  overflow: hidden;

  @include when($small-down) {
    padding: 60px 40px;
    margin-bottom: 10px;
  }

  @include whenmax(399px) {
    padding: 40px 20px;
  }

  &--nobg {
    background: none;
    padding: 0;
    border-radius: 0;
    overflow: visible;

    @include when($medium-down) {
      padding: 0 80px;
    }

    @include when($small-down) {
      padding: 0 40px;
    }

    @include whenmax(399px) {
      padding: 0 20px;
    }
  }

  .outside-wide & {
    max-width: 960px;
  }
}
