@use "src/scss/colors" as *;
@import "src/scss/variables";

html {
  height: 100%;
  overflow-y: scroll;
}

body {
  height: 100%;
  margin: 0;
}

body,
input,
button,
textarea,
select {
  font-family: "Oakes Grotesk", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  font-size: 16px;
  margin: 0;
  background-color: $bgcolor;
  color: $black100;
}

code {
  font-family: monospace;
}

#root {
  height: 100%;
}

#root~iframe {
  z-index: -1 !important;
}