@use "src/scss/colors" as *;
@use "src/scss/mixins" as *;

.OutsideCTA {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 276px;
  background-image: linear-gradient(
    94.68deg,
    #393c7b 0%,
    #4a5494 21.35%,
    #6162a9 40.62%,
    #8563b7 61.46%,
    #a66d73 80.21%,
    #be7c8b 100%
  );
  border-radius: 16px;
  padding: 24px 0;

  color: $purple50;
  font-size: 24px;
  font-weight: 600;
  text-decoration: none;

  @include when($small-down) {
    font-size: 15px;
    width: 176px;
    padding: 16px 0;
  }

  &:hover {
    opacity: 0.9;
  }

  &:active {
    opacity: 1;
    color: $white;
  }

  &__label {
    padding-top: 3px;
    margin: 0 12px 0 0;

    @include when($small-down) {
      margin-right: 8px;
    }
  }

  &__arrow {
    @include when($small-down) {
      width: 16px;
    }
  }
}
