@use "src/scss/colors" as *;
@use "src/scss/mixins" as *;

.OutsideHeader {
  position: fixed;
  top: 0;
  left: 10px;
  right: 10px;
  height: 80px;
  background-color: $bgcolor;
  z-index: 999;
  padding: 0 32px;

  @include when($small-down) {
    height: 72px;
    left: 5px;
    right: 5px;
    padding: 0 8px;
  }

  &::after {
    content: "";
    position: absolute;
    top: 80px;
    left: 0;
    right: 0;
    height: calc(100vh - 80px);
    border-radius: 32px;
    box-shadow: 0 0 0 30px $bgcolor;
    pointer-events: none;
    z-index: -1;

    @include when($small-down) {
      height: calc(100vh - 72px);
      top: 72px;
      border-radius: 24px;
    }
  }

  &__inner {
    height: 100%;
    margin: 0 auto;

    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__logo {
    font-size: 30px;
    font-weight: bold;
    color: $purple100;
    text-transform: lowercase;
    text-decoration: none;
    padding-top: 3px;

    &:hover {
      color: rgba($purple100, 0.8);
    }

    &:active {
      color: rgba($purple100, 0.6);
    }

    @include when($small-down) {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }

    @include whenmax(300px) {
      font-size: 24px;
    }
  }

  &__nav {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include when($small-down) {
      width: 100%;
    }
  }

  &__links {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-right: 16px;

    @include when($medium-down) {
      gap: 2px;
      margin-right: 18px;
    }

    @include when($small-down) {
      display: none;
    }
  }

  &__overlay {
    position: absolute;
    top: 72px;
    left: 0;
    right: 0;
    height: calc(100vh - 82px);
    z-index: -1;
    border-radius: 24px;

    display: none;
    max-height: 0vh;
    overflow: hidden;

    transition: max-height 100ms ease-in-out;

    @include when($small-down) {
      display: block;
    }

    &--open {
      max-height: 100vh;
      transition: max-height 200ms ease-in-out;
    }
  }

  &__overlay-backdrop {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: 100vh;
    z-index: -1;

    background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.15) 0%,
        rgba(0, 0, 0, 0.8) 42.19%
      ),
      linear-gradient(0deg, rgba($purple400, 0.5), rgba($purple400, 0.5)),
      rgba(0, 0, 0, 0.4);
    background-blend-mode: normal, multiply, normal;
    mix-blend-mode: normal;
    box-shadow: inset 0px 0px 8px rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(8px);
  }

  &__overlay-nav {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 32px;

    padding-top: 64px;
  }

  &__overlay-link {
    min-height: 36px;
    display: flex;
    align-items: center;
    font-size: 32px;
    line-height: 40px;
    font-weight: 600;
    color: $white;
    text-decoration: none;
    white-space: nowrap;
    color: $white;
    padding: 0 40px;
    background-color: rgba($white, 0);
    border-radius: 8px;

    &:hover {
      color: $purple100;
    }

    &:active {
      color: $purple200;
    }

    span {
      padding-top: 3px;
    }
  }

  &__link {
    min-height: 36px;
    display: flex;
    align-items: center;
    font-size: 18px;
    line-height: 1.3;
    font-weight: 600;
    color: $white;
    text-decoration: none;
    white-space: nowrap;
    color: $white;
    padding: 0 8px;
    background-color: rgba($white, 0);
    border-radius: 8px;

    @include when($medium-down) {
      font-size: 16px;
    }

    &:hover {
      color: $purple100;
      background-color: rgba($purple100, 0.05);
    }

    &:active {
      background-color: rgba($purple100, 0.1);
    }

    &--current {
      color: $purple200;
      background-color: rgba($purple100, 0.05);

      &:hover {
        color: $purple200;
      }
    }

    span {
      padding-top: 3px;
    }
  }

  &__signin {
    display: inline-flex;
    justify-content: space-around;
    align-items: center;
    white-space: nowrap;
    color: $purple100;
    text-decoration: none;
    border: none;
    border-radius: 8px;
    padding: 0 16px;
    height: 43px;
    cursor: pointer;
    font-size: 18px;
    line-height: 1em;
    font-weight: 600;
    border: 1px solid $purple100;

    @include when($medium-down) {
      font-size: 16px;
    }

    @include when($small-down) {
      font-size: 14px;
      padding: 0 12px;
      height: 38px;
    }

    &:hover {
      background-color: rgba($purple100, 0.05);
    }

    &:active {
      background-color: rgba($purple100, 0.1);
    }

    span {
      padding-top: 3px;
    }
  }

  &__toggle {
    display: inline-flex;
    justify-content: space-around;
    align-items: center;
    white-space: nowrap;
    text-decoration: none;
    border: none;
    border-radius: 8px;
    padding: 0 8px;
    height: 40px;
    cursor: pointer;
    font-size: 18px;
    line-height: 1em;
    font-weight: 600;
    border: 1px solid $purple100;
    background-color: rgba($purple100, 0);
    display: none;

    @include when($small-down) {
      display: block;
    }

    &:hover {
      background-color: rgba($purple100, 0.05);
    }

    &:active {
      background-color: rgba($purple100, 0.1);
    }

    span {
      display: none;
    }

    svg {
      display: block;
    }

    &--toggled {
      background-color: rgba($purple100, 1);

      &:hover {
        background-color: rgba($purple50, 1);
      }

      &:active {
        background-color: rgba($white, 1);
      }

      svg path {
        fill: $bgcolor;
      }
    }
  }
}
