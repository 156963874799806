@use "src/scss/colors" as *;

.DatasourceGuide {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 32px;
  }

  &__title {
    margin-top: 0;

    .Input {
      font-size: 20px;
      padding-top: 4px;
      line-height: 50px;
    }
  }

  &__link {
    color: $black;
  }

  &__submit {
    margin-top: 16px;
  }

  &__validation {
    display: none;

    &--invalid {
      display: block;
      color: $red;
      font-weight: bold;
      font-size: 14px;
      margin-top: 8px;
    }
  }

  &__status {
    margin-top: 32px;
    display: block;
    color: $red;
    font-weight: bold;
    font-size: 18px;

    &--done {
      color: $green;
    }
  }

  &__typedescription {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
}