@use "src/scss/colors" as *;

.CoinsLink {
  color: $black;
  text-decoration: none;
  display: flex;

  height: 50px;
  width: 100%;
  line-height: 50px;

  align-items: center;

  box-sizing: border-box;
  display: flex;
  justify-content: space-between;

  border: 1px solid $black650;
  border-radius: 8px;
  padding: 0 16px;

  font-size: 14px;

  &__loading {
    width: 32px;
    height: auto;
    margin: 0 8px;
  }

  &__label {
    color: $black300;
  }

  &__value {
    color: $black25;
    font-weight: bold;
  }
}
