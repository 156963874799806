@use "src/scss/colors" as *;

.TerminalVisualization {
  --ds-background-neutral-subtle-hovered: #{$black850};
  --ds-background-selected: #{$black800};
  --ds-background-selected-hovered: #{$black875};
  --ds-border-focused: #{$black200};
  --ds-border: #{$black400};
  --ds-text: #{$black300};

  &__chartContainer {
    margin-bottom: 48px;
  }

  &__title {
    margin: 0;
    font-size: 22px;
    line-height: 1.25em;
    color: $purple100;
    font-weight: 400 !important;
    margin-bottom: 32px;
  }

  &__titleSpecial {
    color: $purple200;
    font-weight: bold;
    margin-left: 6px;
    margin-right: 6px;
  }

  &__table {
    overflow: auto;
    max-height: 500px;
  }
}

.datatable-container {
  --dt-padding: 16px;
  --dt-border-color: #{$black850};
  max-width: 1140px;
  min-width: 0px;
  margin: 0 auto;

  .datatable {
    width: 100%;
    padding: var(--dt-padding) var(--dt-padding);
    border-collapse: collapse;

    th {
      padding: var(--dt-padding) var(--dt-padding);
      font-weight: bolder;
      text-align: left;
      border-top: none;
      border-bottom: none;

      position: sticky;
      top: 0;
      background-color: var(--dt-border-color);

      &:first-child {
        border-radius: 8px 0 0 8px;
      }

      &:last-child {
        border-radius: 0 8px 8px 0;
      }
    }

    td {
      padding: var(--dt-padding) var(--dt-padding);
      border-bottom: solid 1px var(--dt-border-color);
    }

    tbody {
      tr {
        &:nth-child(even) {
          background-color: var(--dt-even-row-color);
        }

        &:hover {
          background-color: var(--dt-hover-cell-color);
        }

        .available,
        .away,
        .offline {
          &::after {
            display: inline-block;
            vertical-align: middle;
          }
        }
      }
    }
  }
}