@use "src/scss/colors" as *;

.Sidebar {
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: var(--sidebarWidth);
  z-index: 100;

  background-color: $black875;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: var(--vPadding) var(--hPadding);
  overflow: auto;

  //border-bottom: 1px solid $black;

  box-sizing: border-box;
}