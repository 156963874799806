@use "src/scss/colors" as *;
@use "src/scss/mixins" as *;

.SST {
  margin-top: 16px;
}

.SSTHero {
  position: relative;
  width: 100%;
  max-width: 960px;
  background-color: $black875;
  background: linear-gradient(90deg, $black875 50%, $black850 50%);
  margin: 0 auto 16px;
  border-radius: 16px;
  box-sizing: border-box;
  padding: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  @include when($small-down) {
    padding: 0 16px;
  }

  @include whenmax(650px) {
    padding-bottom: 64px;
  }

  &__image {
    width: 100%;
  }

  &__text {
    position: absolute;
    bottom: 24px;
    left: 0;
    right: 0;

    display: flex;
    padding: 0 16px;
    gap: 32px;

    &>div {
      width: 50%;
      text-align: center;

      color: $black100;
      font-size: 11px;
      font-weight: 700;
      letter-spacing: 1.32px;
      text-transform: uppercase;
      line-height: 1.2em;
    }
  }
}

.SSTFeatures {
  background-color: $black875;
  width: 100%;
  max-width: 960px;
  margin: 0 auto 16px;
  border-radius: 16px;
  box-sizing: border-box;
  padding: 0;
  display: flex;
  flex-direction: column;

  &__items {
    display: flex;
    border-bottom: 1px solid rgba(255, 255, 255, .05);

    @include when($small-down) {
      flex-wrap: wrap;
      border-bottom: none;
    }

  }

  &__item {
    padding: 48px 32px;
    text-align: center;
    flex-basis: 0;
    flex: 1 1 0px;
    box-sizing: border-box;

    @include when($small-down) {
      padding: 24px 16px;
      width: 50%;
      flex: 0 0 50%;
      border-bottom: 1px solid rgba(255, 255, 255, .05);
    }

    svg {
      margin-bottom: 24px;

      @include when($small-down) {
        width: 48px;
        height: 48px;
      }
    }

    div+div {
      margin-top: 4px;
    }
  }

  &__item+&__item {
    border-left: 1px solid rgba(255, 255, 255, .05);
  }

  &__item:nth-child(odd) {
    @include when($small-down) {
      border-left: none;
    }
  }

  &__pretitle {
    color: $purple200;
    text-align: center;
    font-size: 11px;
    font-weight: 700;
    line-height: 1.2em;
    text-transform: uppercase;
    margin-bottom: 4px;
  }

  &__title {
    color: $black25;
    font-size: 18px;
    font-weight: 600;
    line-height: 1.333em;

    @include when($small-down) {
      font-size: 16px;
    }
  }

  &__CTA {
    padding: 32px 32px 28px;
    text-align: center;

    .Button {
      display: inline-flex;
      color: $purple200;
      font-size: 24px;
      font-weight: 500;
      line-height: 1.6667em;
      text-decoration: underline;
      background: none !important;
      padding: 0;

      svg {
        path {
          stroke: $purple200;
        }
      }

      &:hover {
        color: $purple100;

        svg path {
          stroke: $purple100;
        }
      }

      &:active {
        color: $purple300;

        svg path {
          stroke: $purple300;
        }
      }
    }
  }
}

.IMY {
  &__outer {
    background-color: $black875;

    @include when($small-down) {
      margin-bottom: 32px;
    }
  }

  &__title {
    margin: 0 0 8px;
    font-size: 48px;
    font-weight: 600;
    line-height: 1.25;
    color: $white;

    @include when($small-down) {
      font-size: 28px;
    }
  }

  &__text {
    color: $black300;
    font-size: 24px;
    font-weight: 500;
    line-height: 1.333em;

    @include when($small-down) {
      font-size: 16px;
      line-height: 1.5em;
    }

    p {
      margin: 0 0 8px;

      &:last-of-type {
        margin: 0;
      }
    }
  }

  &__box {
    background-color: $black850;
    padding: 56px 48px;
    display: flex;
    border-radius: 16px;
    margin: 64px 0;
    gap: 32px;

    @include when($small-down) {
      flex-direction: column-reverse;
      align-items: center;

      padding: 32px 20px;
    }
  }

  &__quote {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;

    @include when($small-down) {
      align-items: stretch;
    }
  }

  &__quotetext {
    color: $white;
    font-size: 20px;
    font-weight: 500;
    line-height: 1.4em;

    @include when($small-down) {
      font-size: 16px;
      line-height: 1.5em;
    }
  }

  &__image {
    width: 190px;
    flex-shrink: 0;

    @include when($medium) {
      width: 120px;
      margin-top: 20px;
    }

    img {
      max-width: 100%;
    }
  }
}

.Pricing {
  width: 100%;
  max-width: 960px;
  margin: 0 auto 16px;
  border-radius: 16px;
  box-sizing: border-box;
  padding: 0;
  display: flex;
  flex-direction: column;
  margin-bottom: 64px;

  &__header {
    margin: 0;

    @include when($medium-down) {
      margin: 0 32px;
    }

    @include when($small-down) {
      margin: 0 20px;
    }
  }

  &__title {
    color: $white;
    font-size: 48px;
    font-weight: 600;
    line-height: 1.1667em;
    margin: 0 0 4px;

    @include when($small-down) {
      font-size: 32px;
    }
  }

  &__intro {
    color: $black300;
    font-size: 24px;
    font-weight: 500;
    line-height: 1.333em;
    margin: 0 0 32px;

    @include when($small-down) {
      font-size: 16px;
      line-height: 1.5em;
    }

    a {
      color: $black300;
      text-decoration: underline;
    }

    p {
      margin: 0;
    }
  }

  &__boxes {
    display: flex;
    border-radius: 16px;
    overflow: hidden;

    @include whenmax(650px) {
      flex-direction: column;
    }
  }

  &__box {
    background-color: $black850;
    border-right: 1px solid rgba(255, 255, 255, .05);

    @include whenmax(650px) {
      border-right: none;
      margin-bottom: 16px;
      border-radius: 16px;
      overflow: hidden;
    }

    &:last-of-type {
      border-right: none;
    }
  }

  &__head {
    background-color: $black825;
    padding: 32px;
  }

  &__name {
    margin: 0 0 16px;
    color: $white;
    font-size: 26px;
    font-weight: 600;
    line-height: 1.3em;
  }

  &__startsat,
  &__perwhat,
  &__terms {
    color: $black300;
    font-size: 12px;
    font-weight: 600;
    line-height: 1.5em;
  }

  &__price {
    margin: 8px 0 2px;
  }

  &__amount {
    color: $white;
    font-size: 40px;
    font-weight: 600;
    line-height: 1em;
  }

  &__features {
    list-style: none;
    padding: 32px;
    margin: 0;

    li {
      color: $white;
      font-size: 14px;
      font-weight: 600;
      line-height: 1.42em;
      margin-bottom: 16px;

      &:last-of-type {
        margin: 0;
      }
    }
  }

  &__picker {
    margin: 0 32px 32px;
  }
}