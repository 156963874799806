@use "src/scss/colors" as *;

.ContactForm {
  &__row {
    display: flex;
    margin-left: -8px;
    margin-right: -8px;
  }

  &__label {
    display: block;
    margin-bottom: 8px;
  }

  &__input {
    display: block;
    width: 300px;
    box-sizing: border-box;
    line-height: 48px;
    padding: 0 16px;
    font-size: 18px;

    &:disabled {
      background-color: #eee;
      border: 1px solid #ccc;
      color: #888;
    }
  }

  &__hasUnit {
    position: relative;
    display: inline-block;

    &--before {
      .Input {
        padding-left: 40px;
      }
    }

    &--after {
      .Input {
        padding-right: 60px;
      }
    }
  }

  &__unit {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    .AddCoins__row--exchangeRate & {
      color: #888;
    }
  }

  &__hasUnit--before &__unit {
    left: 16px;
  }

  &__hasUnit--after &__unit {
    right: 16px;
  }

  &__textarea {
    display: block;
    width: 100%;
    box-sizing: border-box;
    line-height: 48px;
    padding: 0 16px;
    font-size: 18px;
  }

  &__label--checkbox {
    user-select: none;
    padding-left: 8px;
  }
  &__input--checkbox {
    width: 16px;
    height: 16px;
    margin: 0;
    position: relative;
    bottom: -1px;
  }

  &__termsLink {
    color: $black;
  }

  &__submitButton {
    color: $black;
    text-decoration: none;
    border: 1px solid $black;
    padding: 16px 24px;
    display: inline-block;
    cursor: pointer;
    font-size: 18px;
    background: transparent;
  }

  &__cancelButton {
    color: $black;
    text-decoration: none;
    border: 1px solid transparent;
    padding: 16px;
    display: inline-block;
    cursor: pointer;
  }

  &__validation {
    display: none;

    &--invalid {
      display: block;
      color: $red;
      font-weight: bold;
      font-size: 14px;
      margin-top: 8px;
    }
  }

  &__status {
    margin-top: 32px;
    display: block;
    color: $red;
    font-weight: bold;
    font-size: 18px;

    &--done {
      color: $green;
    }
  }
}
