@use "src/scss/colors" as *;

.PageHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;

  &__titles {
    display: flex;
    flex-direction: column;
  }

  &__pretitle {
    color: $purple300;
    font-size: 32px;
    line-height: 1em;
    margin-bottom: 16px;
  }

  &__title {
    font-weight: normal;
    font-size: 48px;
    line-height: 54px;
    margin: 0;
  }

  &__tags {
    .Tags__tag {
      background-color: $black875;
    }
  }
}