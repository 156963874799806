@use "src/scss/colors" as *;
@use "src/scss/mixins" as *;

.OutsideFooter {
  width: 100%;
  max-width: 840px;
  border-radius: 32px 32px 0 0;
  background-color: $black850;
  margin-bottom: 0;
  padding: 80px 80px 64px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;

  @include when($small-down) {
    padding: 60px 40px;
    margin-bottom: 10px;
  }

  @include whenmax(399px) {
    padding: 40px 20px;
  }

  .outside-wide & {
    max-width: 960px;
  }

  &__logo {
    text-transform: lowercase;
    font-weight: bold;
    font-size: 30px;
    color: $white;
    margin-bottom: 14px;
  }

  &__copyright {
    font-weight: 600;
    font-size: 12px;
    color: #bdbdbd;
  }

  &__nav {
    margin: 8px 0 0 32px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 16px;

    @include when($small-down) {
      margin-left: 16px;
    }

    @include whenmax(549px) {
      gap: 24px;
    }
  }

  &__links,
  &__legal {
    display: flex;
    justify-content: space-between;
    gap: 32px;

    @include when($small-down) {
      gap: 16px;
    }

    @include whenmax(549px) {
      flex-direction: column;
      align-items: flex-end;
      gap: 24px;
    }
  }

  &__link {
    font-size: 16px;
    font-weight: 600;
    color: $white;
    white-space: nowrap;

    &:hover {
      color: rgba($white, 0.85);
    }

    &:active {
      color: rgba($white, 0.7);
    }
  }
}
