@use "src/scss/colors" as *;

.TaskCard {
  background-color: $black850;

  display: flex;
  flex-direction: column;
  border-radius: 8px;
  margin: 24px 0 0;
  overflow: hidden;

  .TasksBox--accepted &:first-child,
  .TasksBox--recurring &:first-child {
    margin-top: 0;
  }

  &__main {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 40px;
    padding-bottom: 24px;
  }

  &__link {
    text-decoration: none;
  }

  &__link &__title {
    padding: 0;
    margin: 0;
    font-size: 26px;
    line-height: 1.14;
    font-weight: 600;
    color: $white;

    &:hover {
      text-decoration: underline;
    }
  }

  &__meta {
    background-color: $black875;
    padding: 24px 30px 40px 30px;
    padding-top: 24px;
    display: flex;
  }

  &__metaBox {
    flex-grow: 1;
    flex-basis: 33.333%;
    margin: 0 10px;
  }

  &__metaHeader {
    display: flex;
    align-items: center;
    margin-bottom: 6px;
  }

  &__metaIcon {
    margin-right: 6px;

    path {
      color: $white;
      stroke-width: 1.5;
    }
  }

  &__metaLabel {
    font-size: 12px;
    font-weight: 600;
    margin: 0;
    margin-top: 2px;
  }

  &__description {
    margin: 0;
  }
}
