@use "src/scss/colors" as *;
@use "src/scss/mixins" as *;

.MediaBox {
  display: flex;
  flex-direction: column;

  &--nomedia,
  &--mediafirst {
    padding-bottom: 80px;

    @include when($small-down) {
      padding-bottom: 60px;
    }

    @include whenmax(399px) {
      padding-bottom: 40px;
      font-size: 16px;
    }
  }

  &__outer.OutsideBox {
    padding-bottom: 0px;
  }

  &__title {
    font-size: 48px;
    font-weight: 600;
    line-height: 1.1em;
    color: $white;
    margin: 0 0 16px;

    @include when($small-down) {
      font-size: 38px;
    }

    @include whenmax(399px) {
      font-size: 32px;
    }
  }

  &__text {
    font-size: 20px;
    font-weight: 600;
    line-height: 1.4em;
    margin: 0 0 60px;
    color: $black200;

    @include whenmax(399px) {
      font-size: 18px;
      margin-bottom: 32px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &--nomedia &__text {
    margin-bottom: 16px !important;
  }

  &__image {
    width: 100%;
    height: auto;

    @include when($small-down) {
      margin-left: -40px;
      width: calc(100% + 80px);
    }

    @include whenmax(399px) {
      margin-left: -20px;
      width: calc(100% + 40px);
    }
  }

  &__video {
    width: 100%;
    height: auto;
    border-radius: 32px 32px 0 0;
    overflow: hidden;

    @include when($small-down) {
      margin-left: -40px;
      width: calc(100% + 80px);
    }

    @include whenmax(399px) {
      margin-left: -20px;
      width: calc(100% + 40px);
    }

    iframe {
      width: 100%;
      display: block;
    }
  }

  &--mediafirst &__video {
    border-radius: 32px;
    margin-bottom: 32px;
  }

  &__moretext {
    font-size: 18px;
    font-weight: 600;
    line-height: 1.25em;
    color: $black300;

    @include whenmax(399px) {
      font-size: 16px;
    }

    ul {
      list-style: none;
      padding: 0 0 0 16px;
      margin: 0 0 16px;
    }

    li {
      margin: 0 0 4px;
      position: relative;
      font-weight: 500;
      font-size: 16px;
      line-height: 1.375;
      color: $black300;

      &::before {
        content: "";
        width: 3px;
        height: 3px;
        position: absolute;
        top: 8px;
        left: -12px;
        background-color: $purple200;
      }

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    p {
      margin: 0 0 8px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  &__link {
    margin: 16px 0 0;
    display: inline-flex;
    align-self: flex-start;
  }
}
