@use "src/scss/colors" as *;

.PageSidebar {
  position: fixed;
  left: 256px;
  top: 0;
  height: 100%;
  width: var(--pageSidebarWidth);
  z-index: 100;

  background-color: $black900;
  border-right: 1px solid $black850;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: var(--vPadding) var(--hPadding);
  overflow: auto;

  box-sizing: border-box;
}