@use "src/scss/colors" as *;

.StatusPill {
  display: inline-flex;
  align-items: center;
  line-height: 1;
  padding: 7px 8px 4px 8px;
  border-radius: 30px;
  background-color: $black825;
  color: $black400;
  font-weight: bold;
  font-size: 11px;
  text-transform: uppercase;

  &--requested {
    color: $purple300;
  }

  &--estimated {
    color: $orange300;
  }

  &--approved {
    color: $green300;
  }

  &--inProgress {
    color: $blue300;
  }

  &--answered {
    color: $turquoise300;
  }

  &--accepted {
    color: $pink300;
  }
}
