@use "src/scss/colors" as *;

.Tabs {
  &__list {
    display: flex;
    padding-left: 0;
    margin: 0 0 32px;
    border-bottom: 1px solid $black800;
  }
}
