/* Make the button round */
.menu-button {
  border-radius: 50%;
  border: 0px;
  background-color: #191919;
}

/* Style the menu container */
.menu-container {
  position: relative;
}

/* Style the menu icon */
.menu-icon {
  color: white;
  transition: background-color 0.3s ease;
}

.menu-icon:hover {
  background-color: #191919;
}

/* Style the menu items */
.menu-items {
  display: none;
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #191919;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}

.menu-items button {
  display: block;
  width: 100%;
  padding: 8px 16px;
  font-size: 14px;
  text-align: left;
  color: #fff;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.menu-items button:hover,
.menu-items button:focus {
  background-color: #212121;
  outline: none;
}

/* Show the menu items when the menu is open */
.menu-items--open {
  display: block;
}
