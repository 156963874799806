@use "src/scss/colors" as *;
@use "src/scss/mixins" as *;

.Field {
  width: 100%;
  margin: 16px 8px;

  --background-color: #{$black850};
  --bg-secondary: #{$black850};
  --border-color: #{$black850};
  --bg-primary: #{$black900};
  --text-primary: #{$black200};
  --inner-border-radius: 4px;
  --outer-borde-radius: 8px;
  --font-weight: 500;
  --font-size: 16px;
  --font-size-small: 13px;
  --font-size-smaller: 11px;

  &Row {
    display: flex;
    margin-left: -8px;
    margin-right: -8px;
  }

  .Label {
    display: block;
    margin-bottom: 16px;
    font-weight: bold;
    font-size: var(--font-size-small);
    color: $black300;
  }

  .Input {
    display: block;
    width: 100%;
    box-sizing: border-box;
    line-height: 40px;
    padding: 0 16px;
    font-size: var(--font-size);
    font-weight: var(--font-weight);
    border: none;
    border-radius: 8px;

    background-color: var(--background-color);
    color: $black200;

    &:disabled {
      background-color: $black800;
      color: $black400;
    }
  }

  .Textarea {
    display: block;
    width: 100%;
    box-sizing: border-box;
    padding: 12px 16px;
    font-size: var(--font-size);
    font-weight: var(--font-weight);
    background-color: var(--background-color);
    color: $black200;
    line-height: 1.75;
    height: 200px;
    border-radius: 8px;
    border: none;
  }

  .Select {
    &__control {
      background-color: var(--bg-secondary);
      border-color: var(--border-color);
      transition: none;
      border-radius: var(--outer-borde-radius);

      &:hover {
        border-color: var(--border-color);
      }
    }

    &__menu {
      background-color: var(--bg-secondary);
      border: 1px solid var(--border-color);
      font-size: var(--font-size);
      font-weight: var(--font-weight);
    }

    &__option {
      background-color: var(--bg-secondary);
      font-weight: var(--font-weight);

      &:hover {
        background-color: var(--bg-primary);
      }
    }

    &__input {
      color: var(--text-primary) !important;
      font-weight: var(--font-weight) !important;
      font-size: var(--font-size) !important;
    }

    &__value-container {
      padding-left: 16px;
    }

    &__indicator-separator {
      background-color: var(--border-color);
    }

    &__placeholder {
      color: var(--text-primary);
      opacity: 0.55;
      font-weight: var(--font-weight);
      font-size: var(--font-size);
      padding-top: 3px;
    }

    &__single-value {
      color: var(--text-primary);
    }

    &__multi-value {
      color: var(--text-primary);
      background-color: var(--bg-primary);
      border-radius: var(--inner-border-radius);

      &__label {
        color: $black300;
        font-size: var(--font-size-small);
        letter-spacing: 0.03em;
        padding: 8px 3px 5px 8px;
        font-weight: var(--font-weight);
      }

      &__remove {
        &:hover {
          color: $red300;
          background-color: $black825;
          cursor: pointer;
        }
      }
    }

    &__group-heading {
      position: sticky;
      top: -5px;
      background-color: var(--bg-secondary);
      padding: 14px 12px 12px;
      font-size: var(--font-size-smaller);
      letter-spacing: 1px;
      font-weight: font-weight("bold");
      color: $black500;
      text-transform: uppercase;
    }
  }
}