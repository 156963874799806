@use "src/scss/colors" as *;

.TerminalEditor {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 32px;
  }

  &__title {
    margin-top: 0;

    .Input {
      font-size: 20px;
      padding-top: 4px;
      line-height: 50px;
    }
  }

  &__link {
    color: $black;
  }

  &__submit {
    margin-top: 16px;
  }

  &__validation {
    display: none;

    &--invalid {
      display: block;
      color: $red;
      font-weight: bold;
      font-size: 14px;
      margin-top: 8px;
    }
  }

  &__status {
    margin-top: 32px;
    display: block;
    color: $red;
    font-weight: bold;
    font-size: 18px;

    &--done {
      color: $green;
    }
  }

  &__exploration {
    padding: 40px;
    border-radius: 16px;
    border: 1px solid $purple300;
    margin-top: 64px;
  }

  &__explorationTitle {
    margin: 0;
    font-size: 32px;
    line-height: 1.25em;
    color: $purple100;
    font-weight: 400 !important;

    strong {
      color: $purple200;
      font-weight: bold;
    }
  }

  pre {
    width: 100%;
    display: block;
    overflow: auto;
  }

  &__toolbarForm {
    background-color: $black875;
    padding: 0 8px 8px 8px;
    border-radius: 8px;
    margin-top: 32px;
    margin-bottom: 64px;

    .Field {
      .Label {
        display: none;
      }
    }
  }

  &__toolbarButtons {
    display: flex;
    gap: 16px;

    .Button {
      font-size: 15px;

      &--danger {
        margin-left: auto;
      }
    }
  }
}

.Datasource {
  position: relative;
  background-color: transparent;
  border-radius: 4px;
  border: 0;
  outline-color: transparent;

  &:hover {
    background-color: $black825;
    cursor: pointer;
  }

  &:active {
    background-color: $black800;
  }

  .Select {
    &__multi-value__label {
      padding: 8px 6px 5px 6px;
    }
  }

  &__dataset {
    display: inline-flex;
    align-items: center;
    color: $purple300;
    font-weight: bold;
    font-size: 11px;
    text-transform: uppercase;
    margin-right: 6px;
  }
}