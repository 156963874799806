@use "src/scss/colors" as *;
@use "src/scss/mixins" as *;

.TimeSpent {
  &__outer.OutsideBox {
    padding: 0px;
    background-color: $outsidepurple1;
    overflow: hidden;
  }

  &__top {
    padding: 80px 80px;

    @include when($small-down) {
      padding: 60px 40px;
    }

    @include whenmax(399px) {
      padding: 40px 20px;
    }
  }

  &__bottom {
    padding: 80px;
    background-color: $outsidepurple2;

    @include when($small-down) {
      padding: 60px 40px;
    }

    @include whenmax(399px) {
      padding: 40px 20px;
    }
  }

  &__title {
    font-size: 48px;
    line-height: 1.25em;
    font-weight: 600;
    max-width: 420px;
    margin: 0 0 16px;

    @include when($small-down) {
      font-size: 38px;
    }

    @include whenmax(399px) {
      font-size: 32px;
    }
  }

  &__text {
    margin: 0;
    color: rgba($purple50, 0.7);
    font-size: 20px;
    font-weight: 600;
    line-height: 1.4em;

    @include whenmax(399px) {
      font-size: 18px;
    }
  }

  &__legend {
    margin-bottom: 16px;
    max-width: 80%;
    height: auto;
  }

  &__chart {
    margin-bottom: 10px;
    max-width: 100%;
    height: auto;

    @include when($small-down) {
      margin-bottom: 2px;
    }
  }

  .SvgChart {
    &__lines {
      stroke: rgba(255, 255, 255, 0.1);
    }

    &__darkBars {
      stroke: rgba(0, 0, 0, 0.25);
      stroke-linecap: round;
      stroke-width: 10;

      @include when($small-down) {
        stroke-width: 16;
      }
    }

    &__bars {
      stroke-linecap: round;
      stroke-width: 10;

      @include when($small-down) {
        stroke-width: 16;
      }

      line {
        transition: stroke-dashoffset 1000ms cubic-bezier(0.83, 0, 0.17, 1);
      }
    }

    &__valueLine {
      stroke-width: 3;
      stroke-dasharray: 8 16;
      stroke-linecap: round;

      @include when($small-down) {
        stroke-width: 6;
      }
    }
  }

  &__labels {
    margin-bottom: 32px;
    max-width: 100%;
    height: auto;

    @include when($small-down) {
      display: none;
    }

    &--diag {
      display: none;

      @include when($small-down) {
        display: block;
      }
    }
  }

  &__control {
    display: flex;
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 16px;
    padding: 16px 26px 16px 32px;
    align-items: center;

    @include when($small-down) {
      padding: 8px 10px 8px 16px;
    }

    @include whenmax(549px) {
      padding: 8px 10px 8px 16px;
    }

    span {
      display: block;
      margin-top: 2px;
    }
  }

  &__enable {
    font-size: 30px;
    line-height: 1.26em;
    color: $purple100;
    font-weight: 600;

    @include when($small-down) {
      font-size: 24px;
    }

    @include whenmax(549px) {
      font-size: 20px;
    }
  }

  &__arrow {
    margin: 0 32px;
    flex-grow: 1;
    position: relative;

    @include when($small-down) {
      margin: 0 10px;
    }

    &::before {
      content: "";
      position: absolute;
      background-image: linear-gradient(90deg, #ab84ff, #7611f8);
      height: 3px;
      top: calc(50% - 2px);
      left: 0;
      right: 0;
    }

    &::after {
      content: "";
      position: absolute;
      height: 15px;
      width: 15px;
      transform: translateY(-50%) rotate(45deg);
      top: calc(50% - 0.5px);
      right: 0;
      border: 0 solid #7611f8;
      border-width: 3px 3px 0 0;
    }
  }

  &__toggle {
    cursor: pointer;
    width: 96px;
    height: 52px;
    border-radius: 100px;
    background-color: $black1000;
    display: block;
    border: none;
    outline: none;
    position: relative;

    @include when($small-down) {
      width: 92px;
      height: 48px;
    }

    &:hover {
      background-color: $black900;
    }

    &:active {
      background-color: $black875;
    }

    &--on {
      background-color: $purple400;

      &:hover {
        background-color: $purple500;
      }

      &:active {
        background-color: $purple400;
      }
    }

    div {
      position: absolute;
      top: 4px;
      bottom: 4px;
      left: 4px;
      width: 44px;
      background-color: #fff;
      border-radius: 100px;
      transition: left 100ms ease-in;

      @include when($small-down) {
        width: 40px;
      }
    }

    input {
      display: none;

      &:checked + div {
        left: 48px;
      }
    }
  }
}
