@use "src/scss/colors" as *;

.Menu {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &__primary {
    display: flex;
    flex-direction: column;
    padding: 40px 0;
  }

  &__secondary {
    display: flex;
    flex-direction: column;
    padding: 0;
  }

  &__item {
    margin-bottom: 8px;
  }

  &__icon {
    width: 16px;
    height: 16px;
  }

  &__CoinsLink {
    margin-bottom: 16px;
  }
}
