@use "src/scss/colors" as *;

$user-bg: $black900;
$user-name: $blue25;
$user-role: $blue200;

$super-bg: $black900;
$super-name: $purple25;
$super-role: $purple200;

.User {
  display: inline-flex;
  align-items: center;
  padding: 6px 16px 6px 6px;
  border-radius: 100px;
  background-color: $black900;

  &__avatar {
    width: 32px;
    height: 32px;
    border-radius: 32px;
    margin-right: 8px;
  }

  &__text {
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
    flex-direction: column;
    margin-top: 2px;
  }

  &__name {
    font-size: 14px;
    margin-bottom: 3px;

    color: $blue25;
  }

  &--super &__name {
    color: $blue200;
  }

  &__role {
    font-size: 10px;
    font-weight: bold;
    text-transform: uppercase;

    color: $blue200;
  }

  &--super &__role {
    color: $purple200;
  }
}
