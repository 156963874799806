@use "src/scss/colors" as *;

.TaskLog {
  &__list {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  &__item {
    display: flex;
    align-items: flex-start;
  }

  &__date {
    height: 52px;
    display: flex;
    align-items: center;

    font-weight: normal;
    font-size: 16px;
    line-height: 1.3;
    color: $black400;
    width: 196px;
    margin: 0;
    padding: 0;

    span {
      padding-top: 3px;
    }
  }

  &__events {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  &__event {
    display: flex;
    align-items: center;
    min-height: 52px;
  }

  &__eventMeta {
    display: flex;
    align-items: center;
    width: 176px;

    .Icon {
      margin-right: 8px;
    }
  }

  &__eventLabel {
    display: flex;
    font-size: 16px;
    font-weight: bold;
    line-height: 1.3;
    color: $black100;
    margin: 0;
    padding: 0;

    span {
      padding-top: 3px;
    }
  }

  &__eventContent {
    display: flex;
    align-items: center;

    .Icon {
      margin-right: 16px;
    }

    .User {
      background-color: $black850;
    }
  }
}
