@use "src/scss/colors" as *;

.Button {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 44px;
  border-radius: 8px;
  border: none;
  white-space: nowrap;

  color: $white;
  font-weight: 600;
  font-size: 16px;
  text-decoration: none;
  padding: 0 16px;
  cursor: pointer;
  font-size: 18px;
  background-color: $purple400;

  &:disabled {
    background-color: $black600;
    color: $black100;

    &:hover,
    &:active {
      color: $black100;
      background-color: $black600;
      cursor: not-allowed;
    }
  }

  &--icon-left {
    .Icon {
      margin-right: 8px;
    }
  }

  &--icon-right {
    .Icon {
      margin-left: 8px;
    }
  }

  .Icon {
    flex-shrink: 0;
    width: 24px;
    height: 24px;

    &--plus {
      path {
        stroke: #fff;
      }
    }
  }

  &:hover {
    background-color: $purple500;
  }

  &:active {
    background-color: $purple400;
    color: $purple100;
  }

  &--danger {
    background-color: $red500;

    &:hover {
      background-color: $red600;
    }

    &:active {
      background-color: $red400;
    }
  }

  &__label {
    padding-top: 4px;
  }
}