@use "src/scss/colors" as *;
@use "src/scss/mixins" as *;

.Outside {
  padding: 80px 10px 0;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  box-sizing: border-box;

  font-size: 16px;
  line-height: 1.5em;
  font-weight: 500;

  /*
  ul { //why???
    padding: 0 0 0 20px;
  }
  */

  @include when($small-down) {
    padding: 72px 5px 0;
  }
}