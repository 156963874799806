@use "src/scss/colors" as *;

.Icon {
  path {
    stroke: $white;
    stroke-width: 2;
    stroke-linejoin: round;
    stroke-linecap: round;
  }
}
