@use "src/scss/colors" as *;

.ImpactList {
  margin-top: 24px;

  &__box {
    border: 1px solid $black825;
    border-radius: 16px;
    padding: 40px;
    margin-bottom: 32px;
  }

  &__welcome {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }

  &__title {
    font-weight: normal;
    font-size: 48px;
    line-height: 54px;
    margin: 0;
  }

  &__logo {
    text-transform: lowercase;
    font-weight: bold;
    color: $purple300;
  }

  &__todo {
    border: 1px solid $purple300;
    border-radius: 16px;
    padding: 40px;
    margin-bottom: 32px;
  }

  &__todoTitle {
    margin: 0;
    font-size: 32px;
    line-height: 1.25em;
    color: $purple100;
    margin-bottom: 24px;
  }

  &__todoTitleCount {
    color: $purple200;
    font-weight: bold;
    display: block;
  }
}

table th {
  text-align: left;
}

.TasksBox {
  &--accepted,
  &--recurring {
    .Task:first-child {
      margin-top: 0;
    }
  }
}
