.HomeLink {

  height: 32px;

  &__link {
    padding: 0 12px;
    text-decoration: none;
    display: flex;
    align-items: center;
    height:100%;
  }
}