$small-start: 368px;
$medium-start: 768px;
$large-start: 1024px;
$huge-start: 1440px;
$humongous-start: 1920px;

$tiny-end: #{$small-start - 1};
$small-end: #{$medium-start - 1};
$medium-end: #{$large-start - 1};
$large-end: #{$huge-start - 1};
$huge-end: #{$humongous-start - 1};

$tiny: '(max-width: #{$tiny-end})';
$small: '(min-width: #{$small-start}) and (max-width: #{$small-end})';
$medium: '(min-width: #{$medium-start}) and (max-width: #{$medium-end})';
$large: '(min-width: #{$large-start}) and (max-width: #{$large-end})';
$huge: '(min-width: #{$huge-start})';
$humongous: '(min-width: #{$humongous-start})';

$small-up: '(min-width: #{$small-start})';
$small-down: '(max-width: #{$small-end})';
$medium-up: '(min-width: #{$medium-start})';
$medium-down: '(max-width: #{$medium-end})';
$large-up: '(min-width: #{$large-start})';
$large-down: '(max-width: #{$large-end})';
$huge-up: '(min-width: #{$huge-start})';
$huge-down: '(max-width: #{$huge-end})';

@function font-weight($weight) {
  @if $weight =="light" {
    @return 300
  }

  @else if $weight =="regular" {
    @return normal
  }

  @else if $weight =="medium" {
    @return 500
  }

  @else if $weight =="semibold" {
    @return 600
  }

  @else if $weight =="bold" {
    @return bold
  }

  @return normal
}

@mixin when($media) {
  @media #{$media} {
    @content;
  }
}

@mixin whenrange($size1, $size2: 0) {
  @if $size2 ==0 {
    @media (min-width: #{$size1}) {
      @content;
    }
  }

  @else {
    @media (min-width: #{$size1}) and (max-width: #{$size2}) {
      @content;
    }
  }
}

@mixin whenmax($media) {
  @media (max-width: #{$media}) {
    @content;
  }
}

@mixin whenmin($media) {
  @media (min-width: #{$media}) {
    @content;
  }
}