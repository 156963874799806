@use "src/scss/colors" as *;

.TaskDetails {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin: 32px 0 24px;
  }

  &__title {
    flex-grow: 1;
    margin-right: 16px;
    margin: 0;
  }

  &__tags.Tags {
    margin-top: 32px;
    margin-bottom: 16px;

    .Tags__tag {
      color: $black300;
      background-color: $black825;
    }
  }

  &__notes {
    font-size: 16px;
    line-height: 28px;
    color: $black100;
    white-space: pre-line;
  }

  &__notesMissing {
    text-transform: uppercase;
    color: $black500;
    font-weight: 600;
    font-size: 14px;
  }
}
