@use "src/scss/colors" as *;
@use "src/scss/mixins" as *;

.SubpageHero {
  &__outer {
    margin: 0 auto 64px;

    @include when($small-down) {
      margin-bottom: 48px;
    }
  }

  &__inner {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    z-index: 2;
    position: relative;

    //padding: 32px 0;

    &--center {
      text-align: center;
    }
  }

  &__title {
    font-size: 60px;
    font-weight: 600;
    line-height: 1.1;
    margin: 0 0 16px;
    padding: 0;
    position: relative;
    z-index: 2;

    @include when($small-down) {
      font-size: 48px;
      margin-bottom: 0;
      line-height: 1.25;
    }

    @include whenmax(399px) {
      font-size: 32px;
    }
  }

  &__tagline {
    font-size: 20px;
    font-weight: 600;
    line-height: 1.4em;
    margin: 0 0 16px;
    position: relative;
    z-index: 2;
    color: $black200;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__bigtagline {
    font-size: 32px;
    font-weight: 500;
    line-height: 1.25em;
    margin: 0 0 16px;
    position: relative;
    z-index: 2;
    color: $black400;

    @include when($small-down) {
      font-size: 20px;
      line-height: 28px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__list {
    font-size: 18px;
    font-weight: 600;
    line-height: 1.3em;
    color: $black300;

    margin: 0 0 16px;
    padding: 0 0 0 20px;

    @include when($medium-down) {
      font-size: 17px;
    }
  }

  &__text {
    font-size: 18px;
    font-weight: 600;
    line-height: 1.4em;
    color: $black300;

    margin-bottom: 32px;

    @include when($medium-down) {
      font-size: 16px;
    }

    p {
      margin: 0 0 16px;

      &:last-of-type {
        margin: 0;
      }
    }
  }
}