@use "src/scss/colors" as *;
@use "src/scss/mixins" as *;

.BookADemo {
  display: flex;
  align-items: center;

  @include when($small-down) {
    align-items: flex-start;
    flex-direction: column;
  }

  &__content {
    display: flex;
    flex-direction: column;

    @include when($small-down) {
      margin-bottom: 48px;
    }
  }

  &__title {
    font-size: 48px;
    font-weight: 600;
    line-height: 1em;
    color: $white;
    margin: 0 0 16px;

    @include when($small-down) {
      font-size: 38px;
    }

    @include whenmax(399px) {
      font-size: 32px;
    }
  }

  &__text {
    font-size: 20px;
    font-weight: 600;
    line-height: 1.2em;
    margin: 0;
    color: rgba($blue50, 0.7);

    @include when($small-down) {
      //text-align: center;
    }

    @include whenmax(399px) {
      font-size: 18px;
    }
  }

  &__button {
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $blue50;
    font-size: 24px;
    font-weight: 600;
    text-decoration: none;
    padding: 32px 24px 32px 32px;
    background-color: $purple400;
    border-radius: 16px;
    margin: 0 0 0 80px;

    @include when($small-down) {
      margin: 0;
    }
    @include whenmax(599px) {
      width: 100%;
      padding: 24px 0;
    }

    &:hover {
      background-color: $purple500;
    }

    &:active {
      background-color: $purple400;
      color: $white;
    }
  }

  &__buttonLabel {
    padding-top: 3px;
    margin: 0 12px 0 0;
    white-space: nowrap;
  }

  &__buttonArrow {
    path {
      stroke: $blue50;
    }
  }
}
