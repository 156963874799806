@use "src/scss/colors" as *;

.SectionHeader {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  border-bottom: 1px solid $black800;
  margin-bottom: 32px;
  min-height: 114px;
  box-sizing: border-box;
}
