.Title {
  font-size: 26px;
  line-height: 38px;
  font-weight: 600;
  margin: 0;
}

h2.Title {
  font-size: 22px;
}

h3.Title {
  font-size: 20px;
}

h4.Title {
  font-size: 18px;
}